import React, { useMemo } from "react"
import { Helmet } from "react-helmet"
import { useLocation } from "@reach/router"
import { useGuestApi } from "../../hooks/useApi"
import hero from "../../resources/images/hero-profile.jpg"

function Meta(props) {
  const { pageInfo } = useGuestApi()
  const { page } = props
  const { href } = useLocation()

  // Home page should contain an extra script for GTM
  const isHome = useMemo(() => page.toLowerCase() === "home", [page])

  return isHome ? (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0,maximum-scale=1.0"
      />
      <title>{`${pageInfo ? pageInfo.page : page} | Play Nostalgie`}</title>
      <meta
        property="og:title"
        content={`${pageInfo ? pageInfo.page : page} | Play Nostalgie`}
      />
      <meta
        property="og:image"
        content={(pageInfo ? pageInfo.shareImage : null) || hero}
      />
      <meta
        property="og:description"
        content={pageInfo ? pageInfo.shareDescription : "Play Nostalgie"}
      />
      <meta
        property="description"
        content={pageInfo ? pageInfo.shareDescription : "Play Nostalgie"}
      />
      <meta property="type" content={pageInfo ? pageInfo.type : "website"} />
      <meta property="og:url" content={href} />
      <meta
        name="robots"
        content={
          process.env.ENVIRONMENT !== "production" ? "noindex,nofollow" : "all"
        }
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Medium.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Light.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Regular.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Bold.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <script
        src="https://www.instagram.com/embed.js?fbclid=IwAR2h6m7xsOIDj7ZNZCQXtJNnJBs5LvcW7olTR_2vGiBcttVNfe-hYDVcIyQ"
        async
      />
      <script>{`
        !function (e, t, n, p, o, a, i, s, c) {
          e[o] || ((i = e[o] = function () {
            i.process ? i.process.apply(i, arguments) : i.queue.push(arguments)
          }).queue = [], i.t = 1 * new Date, (s = t.createElement(n)).async = 1, s.src = "https://cdn.tracking.prepr.io/js/openpixel.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (c = t.getElementsByTagName(n)[0]).parentNode.insertBefore(s, c))
        }(window, document, "script", 0, "opix"), opix("init", "8be5b7ed495237176aae3e6154f0895c3a8f6fad86b5ac257186b44cbb4a9e3e");
      `}</script>
      <script>{`
        var pp_gemius_identifier= 'B9A1Nqt4FTy2KXhQTwv7DXZ27HkZKYQRpMOs1LHPBTH.z7';
        var pp_gemius_extraparameters= new Array('lan=NL', 'key=Nostalgie', 'subs=subsection', 'free=free_field');
        (function(d,t) {
          try {
          var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':'');
          gt.setAttribute('async','async');
          gt.setAttribute('defer','defer');
          gt.src=l+'://gabe.hit.gemius.pl/xgemius.js';
          s.parentNode.insertBefore(gt,s);
          } catch(e) {}})(document,'script');
      `}</script>
      {/* Start of global snippet: Please do not remove
        Place this snippet between the <head> and </head> tags on every page of your site.
        Google tag (gtag.js) */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=DC-13625689"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', 'DC-13625689');`}
      </script>
      {/*  End of global snippet: Please do not remove */}
      {/* Event snippet for VisitLandingPage_Nostalgie [type:Visit][subt:Nostalgie] on : Please do not remove. 
      Place this snippet on pages with events you’re tracking.
      Creation date: 08/24/2023 */}
      <script>
        {`gtag('event', 'conversion', {
          'allow_custom_scripts': true,
          'send_to': 'DC-13625689/invmedia/visit0+standard'
        });`}
      </script>
      <noscript>
        {`<img
          src="https://ad.doubleclick.net/ddm/activity/src=13625689;type=invmedia;cat=visit0;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=$\{GDPR\};gdpr_consent=$\{GDPR_CONSENT_755\};ord=1?"
          width="1"
          height="1"
          alt=""
        />`}
      </noscript>
      {/* End of event snippet: Please do not remove */}
    </Helmet>
  ) : (
    <Helmet>
      <meta charSet="utf-8" />
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0,maximum-scale=1.0"
      />
      <title>{`${pageInfo ? pageInfo.page : page} | Play Nostalgie`}</title>
      <meta
        property="og:title"
        content={`${pageInfo ? pageInfo.page : page} | Play Nostalgie`}
      />
      <meta
        property="og:image"
        content={(pageInfo ? pageInfo.shareImage : null) || hero}
      />
      <meta
        property="og:description"
        content={pageInfo ? pageInfo.shareDescription : "Play Nostalgie"}
      />
      <meta
        property="description"
        content={pageInfo ? pageInfo.shareDescription : "Play Nostalgie"}
      />
      <meta property="type" content={pageInfo ? pageInfo.type : "website"} />
      <meta property="og:url" content={href} />
      <meta
        name="robots"
        content={
          process.env.ENVIRONMENT !== "production" ? "noindex,nofollow" : "all"
        }
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Medium.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Light.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Regular.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <link
        rel="preload"
        as="font"
        href="/fonts/Barlow-Bold.woff2"
        type="font/woff2"
        crossOrigin="anonymous"
      />
      <script
        src="https://www.instagram.com/embed.js?fbclid=IwAR2h6m7xsOIDj7ZNZCQXtJNnJBs5LvcW7olTR_2vGiBcttVNfe-hYDVcIyQ"
        async
      />
      <script>{`
    !function (e, t, n, p, o, a, i, s, c) {
      e[o] || ((i = e[o] = function () {
        i.process ? i.process.apply(i, arguments) : i.queue.push(arguments)
      }).queue = [], i.t = 1 * new Date, (s = t.createElement(n)).async = 1, s.src = "https://cdn.tracking.prepr.io/js/openpixel.min.js?t=" + 864e5 * Math.ceil(new Date / 864e5), (c = t.getElementsByTagName(n)[0]).parentNode.insertBefore(s, c))
    }(window, document, "script", 0, "opix"), opix("init", "8be5b7ed495237176aae3e6154f0895c3a8f6fad86b5ac257186b44cbb4a9e3e");
  `}</script>
      <script>{`
    var pp_gemius_identifier= 'B9A1Nqt4FTy2KXhQTwv7DXZ27HkZKYQRpMOs1LHPBTH.z7';
    var pp_gemius_extraparameters= new Array('lan=NL', 'key=Nostalgie', 'subs=subsection', 'free=free_field');
    (function(d,t) {
      try {
      var gt=d.createElement(t),s=d.getElementsByTagName(t)[0],l='http'+((location.protocol=='https:')?'s':'');
      gt.setAttribute('async','async');
      gt.setAttribute('defer','defer');
      gt.src=l+'://gabe.hit.gemius.pl/xgemius.js';
      s.parentNode.insertBefore(gt,s);
      } catch(e) {}})(document,'script');
  `}</script>
      {/* Start of global snippet: Please do not remove
    Place this snippet between the <head> and </head> tags on every page of your site.
    Google tag (gtag.js) */}
      <script
        async
        src="https://www.googletagmanager.com/gtag/js?id=DC-13625689"
      ></script>
      <script>
        {`window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', 'DC-13625689');`}
      </script>
      {/*  End of global snippet: Please do not remove */}
    </Helmet>
  )
}

export default Meta
