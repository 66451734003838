import React from "react"
import { DidomiSDK } from "@didomi/react"

const didomiConfig = {
  website: {
    name: process.env.DIDOMI_APP_NAME,
    apiKey: process.env.DIDOMI_API_KEY,
    vendors: {
      iab: {
        all: true,
      },
    },
  },
  notice: {
    position: "bottom",
    content: {
      notice: {
        nl: "Door op onze website te blijven kunnen wij of onze partners cookies of toegang instellen en niet-gevoelige informatie op uw apparaat gebruiken om relevante reclame of gespersonaliseerde boodchappen te tonen.",
      },
      learnMore: {
        nl: "Partners bekijken",
      },
    },
  },
  languages: {
    enabled: ["nl"],
    default: "nl",
  },
  theme: {
    color: "#fe6727",
    linkColor: "#fe6727",
  },
  tagManager: {
    provider: 'gtm'
  },
  integrations: {
    vendors: {
      google: {
        enable: true,
        eprivacy: true
      }
    },
    refreshOnConsent: true
  }
}

const Didomi = () => {
  return (
    <DidomiSDK
      config={didomiConfig}
      gdprAppliesGlobally={true}
    />
  )
}

export default Didomi
